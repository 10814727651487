import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';
import { Suspense, lazy, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Traduction_fr from "./Contenu/fr/Url/global.json";
import Traduction_en from "./Contenu/en/Url/global.json";
import Traduction_es from "./Contenu/es/Url/global.json";
import ErrorBoundary from './Error/index.js';
import "./Assets/stylesheets/style.css"

// Import des différents composants de page
const AccueilLazy = lazy(() => import('./Accueil/index.js'));
const SocieteLazy = lazy(() => import('./Societe/index.js'));
const FamilleLazy = lazy(() => import('./Famille/index.js'));
const ProduitLazy = lazy(() => import('./Produit/index.js'));
const FicheLazy = lazy(() => import('./Fiche/index.js'));
const BlogLazy = lazy(() => import('./Blog/index.js'));
const ArticleLazy = lazy(() => import('./Blog/Articles/index.js'));
const DevisLazy = lazy(() => import('./Devis/index.js'));
const ContactLazy = lazy(() => import('./Contact/index.js'));
const PolitiqueLazy = lazy(() => import('./Politique/index.js'));
const MentionsLazy = lazy(() => import('./Mentions/index.js'));
const CookiesLazy = lazy(() => import('./Cookies/index.js'));
const RemerciementLazy = lazy(() => import("./Merci/index.js"))
const SandBoxLazy = lazy(() => import("./SandBox/SandBox.js"))

function App() {

  
  const [language, setLanguage] = useState('');

  useEffect(() => {
    const urlLanguage = window.location.pathname.split('/')[1];
    
    if (urlLanguage && (urlLanguage === 'fr' || urlLanguage === 'en' || urlLanguage === 'es')) {
      setLanguage(urlLanguage);
      Cookies.set('userLanguage', urlLanguage, { expires: 365 });
    } else {
      const savedLanguage = Cookies.get('userLanguage');
      if (savedLanguage && (savedLanguage === 'fr' || savedLanguage === 'en' || savedLanguage === 'es')) {
        setLanguage(savedLanguage);
      } else {
        const browserLanguage = navigator.language.split('-')[0];
        setLanguage(browserLanguage);
        Cookies.set('userLanguage', browserLanguage, { expires: 365 });
      }
    }
  }, []);
  

  

  let defaultlanguage = language;

  let t = Traduction_en;
  if (language === 'fr') {
    t = Traduction_fr;
  } else if (language === 'es') {
    t = Traduction_es;
  }

const router = createBrowserRouter([
    { path: "/", element: <Suspense><AccueilLazy /></Suspense> },
    { path: "/sandbox", element: <Suspense><SandBoxLazy /></Suspense> },

    // LocalHost
    { path: "/fr", element: <Suspense><AccueilLazy /></Suspense> },
    { path: "/fr/societe", element: <Suspense><SocieteLazy /></Suspense> },
    { path: "/fr/famille-de-produit", element: <Suspense><FamilleLazy /></Suspense> },
    { path: "/fr/famille-de-produit/:categorie", element: <Suspense><ProduitLazy /></Suspense> },
    { path: "/fr/famille-de-produit/:categorie/:modele", element: <Suspense><FicheLazy /></Suspense> },
    { path: "/fr/blog", element: <Suspense><BlogLazy /></Suspense> },
    { path: "/fr/blog/:article", element: <Suspense><ArticleLazy /></Suspense> },
    { path: "/fr/demander-un-devis", element: <Suspense><DevisLazy /></Suspense> },
    { path: "/fr/demander-un-devis/merci", element: <Suspense><RemerciementLazy /></Suspense> },
    { path: "/fr/nous-contacter", element: <Suspense><ContactLazy /></Suspense> },
    { path: "/fr/politique-de-confidentialite", element: <Suspense><PolitiqueLazy /></Suspense> },
    { path: "/fr/mentions-legales", element: <Suspense><MentionsLazy /></Suspense> },
    { path: "/fr/politique-des-cookies", element: <Suspense><CookiesLazy /></Suspense> },


    // Domain
    { path: "/societe", element: <Suspense><SocieteLazy /></Suspense> },
    { path: "/famille-de-produit", element: <Suspense><FamilleLazy /></Suspense> },
    { path: "/famille-de-produit/:categorie", element: <Suspense><ProduitLazy /></Suspense> },
    { path: "/famille-de-produit/:categorie/:modele", element: <Suspense><FicheLazy /></Suspense> },
    { path: "/blog", element: <Suspense><BlogLazy /></Suspense> },
    { path: "/blog/:article", element: <Suspense><ArticleLazy /></Suspense> },
    { path: "/demander-un-devis", element: <Suspense><DevisLazy /></Suspense> },
    { path: "/demander-un-devis/merci", element: <Suspense><RemerciementLazy /></Suspense> },
    { path: "/nous-contacter", element: <Suspense><ContactLazy /></Suspense> },
    { path: "/politique-de-confidentialite", element: <Suspense><PolitiqueLazy /></Suspense> },
    { path: "/mentions-legales", element: <Suspense><MentionsLazy /></Suspense> },
    { path: "/politique-des-cookies", element: <Suspense><CookiesLazy /></Suspense> },


    // LocalHost
    { path: "/es", element: <Suspense><AccueilLazy /></Suspense> },
    { path: "/es/empresa", element: <Suspense><SocieteLazy /></Suspense> },
    { path: "/es/familia-de-productos", element: <Suspense><FamilleLazy /></Suspense> },
    { path: "/es/familia-de-productos/:categorie", element: <Suspense><ProduitLazy /></Suspense> },
    { path: "/es/familia-de-productos/:categorie/:modele", element: <Suspense><FicheLazy /></Suspense> },
    { path: "/es/blog", element: <Suspense><BlogLazy /></Suspense> },
    { path: "/es/blog/:article", element: <Suspense><ArticleLazy /></Suspense> },
    { path: "/es/solicitud-de-presupuesto", element: <Suspense><DevisLazy /></Suspense> },
    { path: "/es/solicitud-de-presupuesto/gracias", element: <Suspense><RemerciementLazy /></Suspense> },
    { path: "/es/contacto", element: <Suspense><ContactLazy /></Suspense> },
    { path: "/es/politica-de-confidencialidad", element: <Suspense><PolitiqueLazy /></Suspense> },
    { path: "/es/informacion-legal", element: <Suspense><MentionsLazy /></Suspense> },
    { path: "/es/politica-de-cookies", element: <Suspense><CookiesLazy /></Suspense> },

    // Domain
    { path: "/empresa", element: <Suspense><SocieteLazy /></Suspense> },
    { path: "/familia-de-productos", element: <Suspense><FamilleLazy /></Suspense> },
    { path: "/familia-de-productos/:categorie", element: <Suspense><ProduitLazy /></Suspense> },
    { path: "/familia-de-productos/:categorie/:modele", element: <Suspense><FicheLazy /></Suspense> },
    { path: "/blog", element: <Suspense><BlogLazy /></Suspense> },
    { path: "/blog/:article", element: <Suspense><ArticleLazy /></Suspense> },
    { path: "/solicitud-de-presupuesto", element: <Suspense><DevisLazy /></Suspense> },
    { path: "/solicitud-de-presupuesto/gracias", element: <Suspense><RemerciementLazy /></Suspense> },
    { path: "/contacto", element: <Suspense><ContactLazy /></Suspense> },
    { path: "/politica-de-confidencialidad", element: <Suspense><PolitiqueLazy /></Suspense> },
    { path: "/informacion-legal", element: <Suspense><MentionsLazy /></Suspense> },
    { path: "/politica-de-cookies", element: <Suspense><CookiesLazy /></Suspense> }, 

    // LocalHost
    { path: "/en", element: <Suspense><AccueilLazy /></Suspense> },
    { path: "/en/company", element: <Suspense><SocieteLazy /></Suspense> },
    { path: "/en/product-family", element: <Suspense><FamilleLazy /></Suspense> },
    { path: "/en/product-family/:categorie", element: <Suspense><ProduitLazy /></Suspense> },
    { path: "/en/product-family/:categorie/:modele", element: <Suspense><FicheLazy /></Suspense> },
    { path: "/en/blog", element: <Suspense><BlogLazy /></Suspense> },
    { path: "/en/blog/:article", element: <Suspense><ArticleLazy /></Suspense> },
    { path: "/en/request-a-quotation", element: <Suspense><DevisLazy /></Suspense> },
    { path: "/en/contact-us", element: <Suspense><ContactLazy /></Suspense> },
    { path: "/en/privacy-policy", element: <Suspense><PolitiqueLazy /></Suspense> },
    { path: "/en/legal-information", element: <Suspense><MentionsLazy /></Suspense> },
    { path: "/en/cookie-policy", element: <Suspense><CookiesLazy /></Suspense> },

    // Domain
    { path: "/company", element: <Suspense><SocieteLazy /></Suspense> },
    { path: "/product-family", element: <Suspense><FamilleLazy /></Suspense> },
    { path: "/product-family/:categorie", element: <Suspense><ProduitLazy /></Suspense> },
    { path: "/product-family/:categorie/:modele", element: <Suspense><FicheLazy /></Suspense> },
    { path: "/blog", element: <Suspense><BlogLazy /></Suspense> },
    { path: "/blog/:article", element: <Suspense><ArticleLazy /></Suspense> },
    { path: "/request-a-quotation", element: <Suspense><DevisLazy /></Suspense> },
    { path: "/contact-us", element: <Suspense><ContactLazy /></Suspense> },
    { path: "/privacy-policy", element: <Suspense><PolitiqueLazy /></Suspense> },
    { path: "/legal-information", element: <Suspense><MentionsLazy /></Suspense> },
    { path: "/cookie-policy", element: <Suspense><CookiesLazy /></Suspense> },

    { path: "*", errorElement: <ErrorBoundary /> }
]);

  return (
    <RouterProvider router={router} language={language} errorElement={<ErrorBoundary />} />
  );
}

export default App;



// useEffect(() => {
  //   const urlLanguage = window.location.pathname.split('/')[1];
  //   if (urlLanguage && (urlLanguage === 'fr' || urlLanguage === 'en' || urlLanguage === 'es')) {
  //     setLanguage(urlLanguage);
  //     Cookies.set('userLanguage', urlLanguage, { expires: 365 });
  //   } else if (!urlLanguage) {
  //     // Si la langue n'est pas dans l'URL, rediriger vers la langue par défaut du cookie
  //     const savedLanguage = Cookies.get('userLanguage');
  //     if (savedLanguage && (savedLanguage === 'fr' || savedLanguage === 'en' || savedLanguage === 'es')) {
  //       setLanguage(savedLanguage);
  //       window.location.href = `/${savedLanguage}/`;
  //     } else {
  //       // Si aucune langue n'est définie dans le cookie, utiliser la langue par défaut (anglais)
  //       setLanguage('en');
  //       window.location.href = '/en/';
  //     }
  //   }
  // }, []);